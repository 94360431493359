<template>
    <b-card no-body>
      <validation-observer v-slot="{ handleSubmit }" ref="VForm">
        <b-form @submit.prevent="handleSubmit($parent.submitTarget('VForm', $refs))">
          <b-card-header>
            <b-row>
              <b-col lg="8">
                <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="8">
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Year <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.mt_year" placeholder="e.g 2022">
                      </b-form-input>
                      <VValidate name="Year" v-model="row.mt_year" :rules="mrValidation.mt_year" />
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group>
                      <label>Target <span class="text-danger mr5">*</span></label>
                      <b-input-group prepend="Rp.">
                        <money v-model="row.mt_target" placeholder="cth: 600.00" class="form-control" v-bind="$parent.maskCurrency"></money>
                      </b-input-group>
                      <VValidate name="Target" v-model="row.mt_target" :rules="mrValidation.mt_target" />
                    </b-form-group>
                  </b-col>
                  <b-col md=12>
                    <b-row class="justify-content-end">
                      <b-col md=4 class="text-right mb-4">
                        <b-button type="button" @click.prevent="calculate()" variant="success">Calculate</b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md=12>
                    <table class="table table-striped">
                      <thead>
                        <th scope="col">#</th>
                        <th scope="col">Month</th>
                        <th scope="col">Target</th>
                      </thead>
                      <tbody>
                        <tr v-for="(value, index) in row.month" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ month[value.mt_month - 1] }}</td>
                          <td>
                            <b-form-group>
                              <b-input-group prepend="Rp.">
                                <money id="proPrice" v-model="value.mt_target" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                              </b-input-group>
                              <VValidate :name="'Target ' + (index + 1)" rules="required|min_value:100000" v-model="value.mt_target" />
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" class="text-right">
                            Total:
                          </td>
                          <td class="text-left">
                            {{$parent.formatRupiah(totalTarget)}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col lg="12">
                    <b-form-group>
                      <label>Note <span class="text-muted mr5">(Opsional)</span></label>
                      <b-form-textarea
                        id="textarea"
                        v-model="row.mt_note"
                        placeholder="Enter Note..."
                        rows="4"
                        max-rows="4"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col lg="12" class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">Cancel</button>
                <b-button type="submit" variant="success" class="btn-rounded">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card>
</template>
<script>
import { Money } from 'v-money'


 export default {
    props: {
      row: Object,
      mrValidation: Object,
      Config: Object,
    },
    components:{ Money },
    data(){ 
      return  {
        timeout: null,
        maskCurrency:{
          decimal: ',', thousands: '.', 
          prefix: '', suffix: '', 
          precision:0, masked: false
        },
        typeOptions: [
          { text: 'Year', value: 'Y' },
          { text: 'Month', value: 'M' },
        ],
        month: [
           'Januari',
           'Februari',
           'Maret',
           'April',
           'Mei',
           'Juni',
           'Juli',
           'Agustus',
           'September',
           'Oktober',
           'November',
           'Desember',
        ],
      }
    },
    computed:{
      totalTarget(){
        const total = this.row.month.reduce((a, b) => {
          return a + b.mt_target
          }, 0)

        return total || 0
      }
    },
    methods: {
      calculate(){
        const result = this.row.mt_target / 12
  
        for(let i = 0; i < 12; i++){
          this.row.month[i].mt_target = result
        }
      },
      doSubmitForm() {
        this.$emit('click:submitTarget', 'VForm', this.$refs)
      },
      setMonthlyTarget(){

        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          const result = this.row.mt_target / 12
  
          for(let i = 0; i < 12; i++){
            this.row.month[i].mt_target = result
          }
        }, 400)
      }
    },
 }
</script>
